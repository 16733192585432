import React from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate
} from "react-router-dom";
import { Provider } from "react-redux";
import { store } from "./redux/store";
import "./App.css";

/** Layout **/
import MainLayout from "./layouts/mainLayout";
/** Pages **/
import Home from "./pages/home";
import Products from "./pages/products";
import BusinessUnit from "./pages/businessUnit";
import Packages from "./pages/packagesCrud";
import PrincingInstace from "./pages/pricingInstance";
import CreateEditPricingInstance from "./pages/pricingInstance/createEditPricingInstance";

function App() {
  return (
    <Provider store={store}>
      <Router>
        <MainLayout>
          <Routes>
            <Route path="/" element={<PrincingInstace />} />
            <Route path="/Home" element={<Home />} />
            <Route path="/Products" element={<Products />} />
            <Route path="/BusinessUnit" element={<BusinessUnit />} />
            <Route path="/Packages" element={<Packages />} />
            <Route path="/PricingInstance" element={<PrincingInstace />} />
            <Route
              path="/PricingInstance/CreateEditPricingInstance/:id?"
              element={<CreateEditPricingInstance />}
            />

            <Route path="*" element={<Navigate to="/" />} />
          </Routes>
        </MainLayout>
      </Router>
    </Provider>
  );
}

export default App;
