import { getMonthName } from "../../utils/dateUtils";
import { TooltipComponent } from "../tooltipComponent";

// columns.js
export const textColumn = (columnName, header, width = 150) => ({
  accessorKey: columnName,
  header: header,
  size: width
});

export const hideColumn = (columnName) => ({
  accessorKey: columnName,
  header: "",
  enableHiding: true,

  size: 0, // Set size to 0 for hidden columns
  enableSorting: false, // Desactivar ordenamiento
  enableGlobalFilter: false, // Desactivar el filtro global
  enableColumnFilter: false, // Desactivar filtros de columnas
  enableResizing: false, // Desactivar la redimensión
  Cell: () => null, // Asegurar que no se renderice nada en el cuerpo
  Header: () => null // Asegurar que no se renderice nada en el header
});

export const actionsColumn = (actionBtn, width = 100) => ({
  id: "actions",
  header: "",
  size: width,
  Cell: actionBtn
});

export const tooltipColumn = (columnName, header, width = 150) => ({
  id: columnName,
  header: header,
  size: width,
  Cell: ({ row }) => {
    const cellValue = row.original[columnName];
    const truncatedValue =
      cellValue.length > 100 ? `${cellValue.substring(0, 100)}...` : cellValue;

    return (
      <TooltipComponent title={cellValue}>
        <span className="pointer">{truncatedValue}</span>
      </TooltipComponent>
    );
  }
});

export const monthColumn = (columnName, header, width = 100) => ({
  id: columnName,
  header: header,
  size: width,
  Cell: ({ row }) => {
    const monthNumber = row.original[columnName];
    const monthName = getMonthName(monthNumber);

    return <span>{monthName}</span>;
  }
});

export const moneyColumn = (columnName, header, width = 150) => ({
  id: columnName,
  header: header,
  size: width,
  Cell: ({ row }) => {
    const cellValue = row.original[columnName];

    const formattedValue = new Intl.NumberFormat('en-IN').format(cellValue);

    return <span>{formattedValue}</span>;
  }
});
