import React from "react";
import { Dialog, DialogTitle, DialogContent, DialogActions, Button } from "@mui/material";
import { styled } from "@mui/system";

const ModalComponent = ({ open, onClose, title, children, onSubmit, customWidth }) => {
  const StyledDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialog-paper': {
      width: customWidth || '100%',
      maxWidth: 'calc(100% - 40px)', 
      margin: '20px',
      [theme.breakpoints.down('sm')]: {
        width: 'calc(100% - 40px)', 
      },
    },
  }));

  return (
    <StyledDialog open={open} onClose={onClose} fullWidth>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        {children}
      </DialogContent>
      {/* <DialogActions>
        <Button onClick={onClose} color="secondary">
          Cancel
        </Button>
        <Button onClick={onSubmit} color="primary">
          Submit
        </Button>
      </DialogActions> */}
    </StyledDialog>
  );
};

export default ModalComponent;
