import React from "react";
import { Controller } from "react-hook-form";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import { NumericFormat } from "react-number-format";

const MoneyTextInput = ({
  name,
  control,
  label,
  defaultValue = "0",
  rules = {},
  disabled = false
}) => {
  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue}
      rules={rules}
      render={({ field, fieldState: { error } }) => (
        <div style={{ paddingTop: "15px" }}>
          <NumericFormat
            {...field}
            customInput={TextField}
            label={label}
            variant="outlined"
            fullWidth
            thousandSeparator=","
            // decimalSeparator=","
            allowLeadingZeros
            // fixedDecimalScale={true}
            // decimalScale={1}
            error={!!error}
            helperText={error ? error.message : null}
            disabled={disabled}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">$</InputAdornment>
              )
            }}
          />
        </div>
      )}
    />
  );
};

export default MoneyTextInput;
