import React from "react";
import {
  MaterialReactTable,
  useMaterialReactTable
} from "material-react-table";
import { Box, TextField, IconButton } from "@mui/material";
import FilterListIcon from "@mui/icons-material/FilterList";
import ClearIcon from "@mui/icons-material/Clear";
import { spanishTableText } from "./tableTexts";
import icons from "../../constants/icons";

const TableComponent = ({
  columns,
  data,
  onRowClick,
  selectedRows,
  multiSelect = false,
  enableGlobalFilter = false,
  headerButtons = null
}) => {
  const table = useMaterialReactTable({
    columns,
    data,
    enableGlobalFilter,
    getRowId: (row) => row.id || row.userId || row.featuresId,
    localization: spanishTableText,
    muiTableBodyRowProps: ({ row }) => ({
      onClick: () => {
        onRowClick && onRowClick(row.original);
      },
      selected: multiSelect
        ? selectedRows.some((selectedRow) => selectedRow.id === row.original.id)
        : selectedRows?.id === row.original.id,
      sx: {
        cursor: onRowClick ? "pointer" : "initial",
        backgroundColor: multiSelect
          ? selectedRows.some(
              (selectedRow) => selectedRow.id === row.original.id
            )
            ? "#e0f7fa"
            : "inherit"
          : selectedRows?.id === row.original.id
          ? "#e0f7fa"
          : "inherit"
      }
    })
  });

  const renderGlobalFilter = () =>
    enableGlobalFilter && (
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <TextField
          placeholder="Buscar..."
          variant="outlined"
          size="small"
          value={table.getState().globalFilter || ""}
          onChange={(e) => table.setGlobalFilter(e.target.value)}
          InputProps={{
            startAdornment: <FilterListIcon sx={{ mr: 1 }} />
          }}
        />
        <IconButton onClick={() => table.setGlobalFilter("")} sx={{ ml: 1 }}>
          <img
            className="w-icon-25 icon-margin-r-15"
            src={icons.clearFilter}
            alt="icon"
          />
        </IconButton>
      </Box>
    );

  const renderTableToolbar = () => (
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        mb: 2
      }}
    >
      {renderGlobalFilter()}
      {headerButtons}
    </Box>
  );

  return (
    <>
      {renderTableToolbar()}
      <MaterialReactTable table={table} />
    </>
  );
};

export default TableComponent;
