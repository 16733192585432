export const spanishTableText = {
  actions: "Acciones",
  and: "y",
  cancel: "Cancelar",
  changeFilterMode: "Cambiar modo de filtro",
  changeSearchMode: "Cambiar modo de búsqueda",
  clearFilter: "Limpiar filtro",
  clearSearch: "Limpiar búsqueda",
  clearSelection: "Limpiar selección",
  clearSort: "Limpiar orden",
  clickToCopy: "Clic para copiar",
  copy: "Copiar",
  collapse: "Colapsar",
  collapseAll: "Colapsar todo",
  columnActions: "Acciones de columna",
  copiedToClipboard: "Copiado al portapapeles",
  dropToGroupBy: "Soltar para agrupar por {column}",
  edit: "Editar",
  expand: "Expandir",
  expandAll: "Expandir todo",
  filterArrIncludes: "Incluye",
  filterArrIncludesAll: "Incluye todo",
  filterArrIncludesSome: "Incluye",
  filterBetween: "Entre",
  filterBetweenInclusive: "Entre inclusivo",
  filterByColumn: "Filtrar por {column}",
  filterContains: "Contiene",
  filterEmpty: "Vacío",
  filterEndsWith: "Termina con",
  filterEquals: "Igual a",
  filterEqualsString: "Igual a",
  filterFuzzy: "Difuso",
  filterGreaterThan: "Mayor que",
  filterGreaterThanOrEqualTo: "Mayor o igual a",
  filterInNumberRange: "Entre",
  filterIncludesString: "Contiene",
  filterIncludesStringSensitive: "Contiene",
  filterLessThan: "Menor que",
  filterLessThanOrEqualTo: "Menor o igual a",
  filterMode: "Modo de filtro: {filterType}",
  filterNotEmpty: "No vacío",
  filterNotEquals: "No igual a",
  filterStartsWith: "Comienza con",
  filterWeakEquals: "Igual a",
  filteringByColumn: "Filtrando por {column} - {filterType} {filterValue}",
  goToFirstPage: "Ir a la primera página",
  goToLastPage: "Ir a la última página",
  goToNextPage: "Ir a la siguiente página",
  goToPreviousPage: "Ir a la página anterior",
  grab: "Agarrar",
  groupByColumn: "Agrupar por {column}",
  groupedBy: "Agrupado por ",
  hideAll: "Ocultar todo",
  hideColumn: "Ocultar columna {column}",
  max: "Máx",
  min: "Mín",
  move: "Mover",
  noRecordsToDisplay: "No hay registros para mostrar",
  noResultsFound: "No se encontraron resultados",
  of: "de",
  or: "o",
  pin: "Fijar",
  pinToLeft: "Fijar a la izquierda",
  pinToRight: "Fijar a la derecha",
  resetColumnSize: "Restablecer tamaño de columna",
  resetOrder: "Restablecer orden",
  rowActions: "Acciones de fila",
  rowNumber: "#",
  rowNumbers: "Números de fila",
  rowsPerPage: "Filas por página",
  save: "Guardar",
  search: "Buscar",
  selectedCountOfRowCountRowsSelected:
    "{selectedCount} de {rowCount} fila(s) seleccionada(s)",
  select: "Seleccionar",
  showAll: "Mostrar todo",
  showAllColumns: "Mostrar todas las columnas",
  showHideColumns: "Mostrar/Ocultar columnas",
  showHideFilters: "Mostrar/Ocultar filtros",
  showHideSearch: "Mostrar/Ocultar búsqueda",
  sortByColumnAsc: "Ordenar por {column} ascendente",
  sortByColumnDesc: "Ordenar por {column} descendente",
  sortedByColumnAsc: "Ordenado por {column} ascendente",
  sortedByColumnDesc: "Ordenado por {column} descendente",
  thenBy: ", luego por ",
  toggleDensity: "Alternar densidad",
  toggleFullScreen: "Alternar pantalla completa",
  toggleSelectAll: "Alternar seleccionar todo",
  toggleSelectRow: "Alternar seleccionar fila",
  toggleVisibility: "Alternar visibilidad",
  ungroupByColumn: "Desagrupar por {column}",
  unpin: "Desanclar",
  unpinAll: "Desanclar todo"
};
