import React, { useEffect, useState } from "react";
import ModalComponent from "../../components/modalComponent";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import TextInput from "../../components/textInput";
import {
  callApiAsync,
  getBusinessUnits,
  getPackageById
} from "../../services/apiService";
import SelectInput from "../../components/selectInput";
import {
  status_select,
  transformToSelectData
} from "../../utils/selectsValues";
import ButtonComponent from "../../components/button";
import SearchableSelectInput from "../../components/selectInput/searchableSelectInput";
import DatePickerInput from "../../components/datepickerInput";
import dayjs from "dayjs";
import FormRadioButton from "../../components/formRadio";
import { Grid } from "@mui/material";

const CreateEditPackages = ({ rowData, open, onClose, onSubmitFuntion }) => {
  const [bussinesUnit, setBussinesUnit] = useState([]);
  const [showExtraField, setShowExtraField] = useState(false);

  const schema = yup.object().shape({
    name: yup.string().required("El nombre del Paquete es requerido"),
    idBusinessUnit: yup.string().required("La unidad de negocio es requerida"),
    description: yup
      .string()
      .required("La descripción es requerida")
      .max(500, "La descripción no puede tener más de 500 caracteres")
  });

  const { control, handleSubmit, reset } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      name: rowData.name || "",
      description: rowData.description || "",
      idBusinessUnit: rowData.idBusinessUnit ?? null,
      initialDate: rowData.initialDate ? dayjs(rowData.initialDate) : null,
      endDate: rowData.endDate ? dayjs(rowData.endDate) : null,
      temporal: "0"
    }
  });

  const getBusinessUnitSelect = () => {
    callApiAsync(getBusinessUnits, (data) => {
      const selectBussinessUnit = transformToSelectData(data);
      setBussinesUnit(selectBussinessUnit);
    });
  };

  const getPackageData = (packageId) => {
    callApiAsync(
      () => getPackageById(packageId),
      (data) => {
        const temporalValue = data.temporal ? "1" : "0";
        setShowExtraField(data.temporal ? true : false);
        reset({
          name: data.name || "",
          description: data.description || "",
          idBusinessUnit: data.idBusinessUnit || null,
          initialDate: data.initialDate ? dayjs(data.initialDate) : null,
          endDate: data.endDate ? dayjs(data.endDate) : null,
          temporal: temporalValue
        });
        setShowExtraField(temporalValue === "1");

        console.log("data getPackageData", data);
      }
    );
  };

  useEffect(() => {
    getBusinessUnitSelect();
  }, []);

  useEffect(() => {
    if (open) {
      if (rowData.id) {
        getPackageData(rowData.id);
      } else {
        reset({
          name: "",
          description: "",
          idBusinessUnit: null,
          initialDate: null,
          endDate: null,
          temporal: "0"
        });
        setShowExtraField(false);
      }
    }
  }, [open, rowData, reset]);

  const handleFormSubmit = (formData) => {
    console.log("formData", formData.temporal);
    const updatedData = {
      ...formData,
      temporal: formData.temporal === "1",
      initialDate: formData.initialDate
        ? dayjs(formData.initialDate).format("YYYY-MM-DDTHH:mm:ss")
        : null,
      endDate: formData.endDate
        ? dayjs(formData.endDate).format("YYYY-MM-DDTHH:mm:ss")
        : null
    };

    console.log("updatedData", updatedData);

    rowData.id
      ? onSubmitFuntion({ id: rowData.id, ...updatedData })
      : onSubmitFuntion({ id: 0, status: 1, ...updatedData });

    reset();
    onClose();
  };

  return (
    <div>
      <ModalComponent
        open={open}
        onClose={onClose}
        onSubmit={handleSubmit(handleFormSubmit)}
        title={rowData.id ? "Editar Paquetes" : "Crear Paquetes"}
      >
        <form onSubmit={handleSubmit(handleFormSubmit)}>
          <TextInput name="name" control={control} label="Nombre Paquete" />

          <div className="mt-15">
            <SearchableSelectInput
              name="idBusinessUnit"
              control={control}
              label="Unidad de negocio"
              options={bussinesUnit}
            />
          </div>

          <TextInput
            name="description"
            control={control}
            label="Descripción"
            isTextArea={true}
            rows={6}
            maxLength={500}
            rules={{ required: "Este campo es obligatorio" }}
            defaultValue={rowData.description || ""}
          />

          <FormRadioButton
            name="temporal"
            control={control}
            label="Temporal"
            options={[
              { label: "si", value: "1" },
              { label: "no", value: "0" }
            ]}
            onChange={(value) => {
              setShowExtraField(value === "1");
            }}
          />

          {showExtraField && (
            <div className="mt-15">
              <Grid container spacing={2}>
                <Grid item md={6} xs={12}>
                  <DatePickerInput
                    name="initialDate"
                    control={control}
                    label="Seleccione una fecha de inicio"
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <DatePickerInput
                    name="endDate"
                    control={control}
                    label="Seleccione una fecha de fin"
                  />
                </Grid>
              </Grid>
            </div>
          )}

          {rowData.id ? (
            <div className="HContainer mt-15">
              <SelectInput
                name="status"
                control={control}
                label="Estado"
                options={status_select}
                defaultValue={rowData.status ?? 1}
              />
            </div>
          ) : null}

          <div className="HContainer j-center mt-20">
            <ButtonComponent
              type="submit"
              backgroundColor="#F6C079"
              textColor="#000000"
              width="160px"
            >
              Guardar
            </ButtonComponent>
          </div>
        </form>
      </ModalComponent>
    </div>
  );
};

export default CreateEditPackages;
