import React, { useEffect, useState, useMemo } from "react";
import ModalComponent from "../../../components/modalComponent";
import TableComponent from "../../../components/tableComponent";
import ButtonComponent from "../../../components/button";
import {
  hideColumn,
  textColumn
} from "../../../components/tableComponent/columns";
import {
  callApiAsync,
  getFeaturesByProduct,
  getMainProducts
} from "../../../services/apiService";
import SelectInput from "../../../components/selectInput";
import { useForm } from "react-hook-form";
import SearchableSelectInput from "../../../components/selectInput/searchableSelectInput";

function transformArray(data) {
  return data.map((item) => ({
    value: item.id,
    label: item.productName
  }));
}

const SelectFeatureModal = ({ open, onClose, onSubmit, selectedFeatures }) => {
  const [featureTable, setFeatureTable] = useState([]);
  const [localSelectedFeatures, setLocalSelectedFeatures] = useState([]);
  const [selectProduct, setSelectProduct] = useState([]);

  const { control, watch, setValue, reset } = useForm({
    defaultValues: {
      product: ""
    }
  });

  const selectedProduct = watch("product");

  // Obtener productos principales para el filtro
  const getProductTable = () => {
    callApiAsync(getMainProducts, (data) => {
      setSelectProduct(transformArray(data));
    });
  };

  // Obtener los features de un producto específico
  const getFeaturesTable = () => {
    if (selectedProduct) {
      callApiAsync(
        () => getFeaturesByProduct(selectedProduct),
        (data) => {
          const filteredFeatures = data.filter(
            (feature) =>
              !localSelectedFeatures.some(
                (selectedFeature) => selectedFeature.id === feature.id
              )
          );
          setFeatureTable(filteredFeatures);
        }
      );
    }
  };

  // Cargar productos al abrir el modal
  useEffect(() => {
    getProductTable();
  }, []);

  // Cargar features cuando se selecciona un producto
  useEffect(() => {
    if (selectedProduct) {
      getFeaturesTable();
    }
  }, [selectedProduct]);

  // Al abrir el modal, cargar las características seleccionadas del backend
  useEffect(() => {
    if (Array.isArray(selectedFeatures)) {
      setLocalSelectedFeatures([...selectedFeatures]);
    } else if (selectedFeatures && selectedFeatures.selectedFeatures) {
      // Si es un objeto con una propiedad "selectedFeatures"
      setLocalSelectedFeatures([...selectedFeatures.selectedFeatures]);
    } else {
      setLocalSelectedFeatures([]);
    }
  }, [selectedFeatures]);

  // Manejar la selección de una fila de la tabla
  const handleFeatureRowClick = (rowData) => {
    const isSelected = localSelectedFeatures.some(
      (feature) => feature.id === rowData.id
    );
    if (isSelected) {
      // Si ya está seleccionado, removerlo
      setLocalSelectedFeatures(
        localSelectedFeatures.filter((feature) => feature.id !== rowData.id)
      );
    } else {
      // Si no está seleccionado, agregarlo
      setLocalSelectedFeatures([...localSelectedFeatures, rowData]);
    }
  };

  // Guardar las características seleccionadas y cerrar el modal
  const handleSave = () => {
    onSubmit(localSelectedFeatures);
    handleClose();
  };

  // Cerrar el modal y restablecer el estado
  const handleClose = () => {
    resetModalState();
    onClose();
  };

  // Restablecer el estado del modal
  const resetModalState = () => {
    setFeatureTable([]);
    reset({ product: "" });
  };

  const featureTableColumns = useMemo(
    () => [
      hideColumn("id", "idFeature"),
      textColumn("name", "Nombre del Feature")
    ],
    []
  );

  return (
    <ModalComponent
      customWidth={"600px"}
      open={open}
      onClose={handleClose}
      title={"Selecciona Característica / Feature"}
    >
      <div className="mt-10">
        <SearchableSelectInput
          name="product"
          control={control}
          label="Productos"
          defaultValue={0}
          onChange={(e) => setValue("product", e.target.value)}
          options={selectProduct}
        />
      </div>
      <div className="mt-10">
        <TableComponent
          columns={featureTableColumns}
          data={featureTable}
          onRowClick={handleFeatureRowClick}
          selectedRows={localSelectedFeatures}
          multiSelect={true}
          enableGlobalFilter={true}
        />
      </div>
      <div className="VContainer">
        <p>Features seleccionados: {localSelectedFeatures.length}</p>
        <div className="HContainer j-center">
          <ButtonComponent
            onClick={handleSave}
            type="button"
            backgroundColor="#F6C079"
            textColor="#000000"
          >
            Guardar
          </ButtonComponent>
        </div>
      </div>
    </ModalComponent>
  );
};

export default SelectFeatureModal;
