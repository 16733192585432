import React, { useEffect, useMemo, useState } from "react";
import ButtonComponent from "../../components/button";
import icons from "../../constants/icons";
import TableComponent from "../../components/tableComponent";
import {
  actionsColumn,
  hideColumn,
  textColumn,
  tooltipColumn
} from "../../components/tableComponent/columns";
import StatusPill from "../../components/statusPill";
import CreateEditPackages from "./createEditPackages";
import {
  callApiAsync,
  deletePackage,
  getPackage,
  packageCreateOrUpdate
} from "../../services/apiService";
import AlertComponent from "../../components/alertModal";
import ToastNotification from "../../components/toastNotification";

const Packages = () => {
  const [packageTable, setPackageTable] = useState([]);
  const [selectedRow, setSelectedRow] = useState({});
  const [openCreateEdit, setOpenCreateEdit] = useState(false);

  const [alertOpen, setAlertOpen] = useState(false);

  const [toastMessage, setToastMessage] = useState("");
  const [toastOpen, setToastOpen] = useState(false);
  const [toastSeverity, setToastSeverity] = useState("warning");

  const getPackages = () => {
    callApiAsync(
      () => getPackage(false),
      (data) => {
        setPackageTable(data);
      }
    );
  };

  const deletePackageBtn = (packagedata) => {
    callApiAsync(
      () => deletePackage(packagedata.id),
      () => {
        setToastSeverity("success");
        setToastMessage("El paquete se eliminó exitosamente.");
        setToastOpen(true);
        getPackages();
      },
      (error) => {
        setToastSeverity("error");
        setToastMessage(error);
        setToastOpen(true);
      }
    );
  };

  useEffect(() => {
    getPackages();
  }, []);

  const handleTableDelete = (row) => {
    setSelectedRow(row);
    setAlertOpen(true);
  };

  const handleDelete = () => {
    deletePackageBtn(selectedRow);
    setSelectedRow({});
  };

  const actionBtn = ({ row }) => (
    <div className="HContainer">
      <ButtonComponent
        onClick={() => handleCreateEdit(row.original)}
        variant="contained"
        backgroundColor="#fff0"
      >
        <img className="btn-icon" src={icons.blue_edit} alt="icon" />
      </ButtonComponent>
      <ButtonComponent
        onClick={() => handleTableDelete(row.original)}
        variant="contained"
        backgroundColor="#fff0"
      >
        <img className="btn-icon" src={icons.red_delete} alt="icon" />
      </ButtonComponent>
    </div>
  );

  const handleClose = () => {
    setOpenCreateEdit(false);
  };

  const handleCreateEdit = (row) => {
    if (!row) {
      setSelectedRow({});
      setOpenCreateEdit(true);
      return;
    }
    setSelectedRow(row);
    setOpenCreateEdit(true);
  };

  const columns = useMemo(
    () => [
      textColumn("name", "Nombre del paquete"),
      tooltipColumn("description", "Descripción"),
      hideColumn("idBusinessUnit", "idBusinessUnit"),
      textColumn("businessUnitName", "Nombre de la unidad de negocio"),
      {
        id: "temporal",
        header: "Temporal",
        size: 20,
        Cell: ({ row }) => <>{row.original.temporal === true ? "Si" : "No"}</>
      },
      {
        id: "status",
        header: "Estado",
        size: 50,
        Cell: ({ row }) => (
          <StatusPill
            backgroundColor={row.original.status === 1 ? "#4ADE80" : "#D9D9D9"}
            textColor={"#000000"}
          >
            {row.original.status === 1 ? "Activo" : "Inactivo"}
          </StatusPill>
        )
      },
      actionsColumn(actionBtn, 50)
    ],
    []
  );

  const createPackage = (formData) => {
    console.log("formData", formData);
    callApiAsync(
      () => packageCreateOrUpdate(formData),
      () => {
        setToastSeverity("success");
        setToastMessage(
          `Se ${formData.id === 0 ? "creó" : "edito"} el paquete exitosamente.`
        );
        setToastOpen(true);
        getPackages();
      }
    );
  };

  return (
    <div>
      <h1>Paquetes</h1>
      <div>
        <TableComponent
          columns={columns}
          data={packageTable}
          enableGlobalFilter={true}
          headerButtons={
            <>
              <ButtonComponent
                onClick={() => handleCreateEdit()}
                type="submit"
                variant="contained"
                backgroundColor="#F6C079"
                textColor="#000000"
                margin="15px 0"
              >
                <div className="HContainer a-center">
                  <img
                    className="btn-icon icon-margin-r-15"
                    src={icons.plus}
                    alt="icon"
                  />
                  <p>Crear Paquetes</p>
                </div>
              </ButtonComponent>
            </>
          }
        />
      </div>

      <CreateEditPackages
        rowData={selectedRow}
        open={openCreateEdit}
        onClose={handleClose}
        onSubmitFuntion={(data) => createPackage(data)}
      />

      <AlertComponent
        open={alertOpen}
        onClose={() => setAlertOpen(false)}
        onConfirm={handleDelete}
        title="Confirmar Eliminación"
        message="¿Estás seguro de que deseas eliminar este paquete? Esta acción no se puede deshacer."
      />
      <ToastNotification
        open={toastOpen}
        handleClose={() => setToastOpen(false)}
        message={toastMessage}
        severity={toastSeverity}
      />
    </div>
  );
};

export default Packages;
