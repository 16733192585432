import React, { useEffect, useState } from "react";
import { callApiAsync, getPackageById } from "../../../services/apiService";
import { Grid } from "@mui/material";

const SelectedPackageDisplay = ({ packageId }) => {
  const [packageData, setPackageData] = useState({});

  useEffect(() => {
    if (packageId) {
      callApiAsync(
        () => getPackageById(packageId),
        (data) => {
          setPackageData(data);
        }
      );
    }
  }, [packageId]);

  return (
    <div>
      <Grid container spacing={2}>
        <Grid item xs={4}>
          <strong>Nombre:</strong> {packageData.name}
        </Grid>
        <Grid item xs={8}>
          <strong>Descripción:</strong> {packageData.description}
        </Grid>
        <Grid item xs={4}>
          <strong>Unidad de Negocio:</strong> {packageData.businessUnitName}
        </Grid>
        <Grid item xs={4}>
          <strong>País:</strong> {packageData.countryName}
        </Grid>
        <Grid item xs={4}>
          <strong>Estado:</strong>
          {packageData.status === 1 ? "Activo" : "Inactivo"}
        </Grid>
      </Grid>
    </div>
  );
};

export default SelectedPackageDisplay;
