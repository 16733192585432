import React from "react";
import { Link } from "react-router-dom"; // Importar Link de react-router-dom
import ButtonComponent from "../../components/button";

const Home = () => {
  return (
    <div>
      <h1>Home</h1>
      {/* <div className="HContainer">
        <Link to="/Products" style={{ textDecoration: "none" }}>
          <ButtonComponent
            type="submit"
            variant="contained"
            backgroundColor="#F6C079"
            textColor="#000000"
            margin="0 10px 0 0"
          >
            <p>Productos</p>
          </ButtonComponent>
        </Link>
        <Link to="/BusinessUnit" style={{ textDecoration: "none" }}>
          <ButtonComponent
            type="submit"
            variant="contained"
            backgroundColor="#33ADFF"
            textColor="#000000"
            margin="0 10px"
          >
            <p>Unidad de negocio</p>
          </ButtonComponent>
        </Link>
        <Link to="/Packages" style={{ textDecoration: "none" }}>
          <ButtonComponent
            type="submit"
            variant="contained"
            backgroundColor="#F6C079"
            textColor="#000000"
            margin="0 10px"
          >
            <p>Paquetes</p>
          </ButtonComponent>
        </Link>
        <Link to="/PricingInstance" style={{ textDecoration: "none" }}>
          <ButtonComponent
            type="submit"
            variant="contained"
            backgroundColor="#33ADFF"
            textColor="#000000"
            margin="0 10px"
          >
            <p>Elementos facturación</p>
          </ButtonComponent>
        </Link>
      </div> */}
    </div>
  );
};

export default Home;
