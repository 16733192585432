import React from "react";
import { Controller } from "react-hook-form";
import {
  RadioGroup,
  FormControlLabel,
  Radio,
  FormControl,
  FormLabel,
  FormHelperText
} from "@mui/material";

const FormRadioButton = ({
  name,
  control,
  label,
  options,
  onChange,
  defaultValue = "",
  rules = {}
}) => {
  return (
    <FormControl component="fieldset" error={!!rules.error}>
      <FormLabel component="legend">{label}</FormLabel>
      <Controller
        name={name}
        control={control}
        defaultValue={defaultValue}
        rules={rules}
        render={({ field, fieldState: { error } }) => (
          <>
            <RadioGroup
              {...field}
              onChange={(e) => {
                field.onChange(e.target.value);
                onChange && onChange(e.target.value);
              }}
              value={field.value || defaultValue}
            >
              <div className="HContainer">
                {options.map((option, index) => (
                  <FormControlLabel
                    key={index}
                    value={option.value}
                    control={<Radio />}
                    label={option.label}
                  />
                ))}
              </div>
            </RadioGroup>
            {error && <FormHelperText>{error.message}</FormHelperText>}
          </>
        )}
      />
    </FormControl>
  );
};

export default FormRadioButton;
