import React, { useState } from "react";
import ModalComponent from "../../../components/modalComponent";
import { months_select } from "../../../utils/selectsValues";
import {
  generateYear_select,
  getCurrentMonth,
  getCurrentYear
} from "../../../utils/dateUtils";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import SelectInput from "../../../components/selectInput";
import ButtonComponent from "../../../components/button";
import {
  callApiAsync,
  createBillingPeriod
} from "../../../services/apiService";
import ToastNotification from "../../../components/toastNotification"; // Importa el componente de toast

const BillingPeriod = ({ open, onClose, packageId, onSubmit }) => {
  const [formData, setFormData] = useState({});

  const [toastMessage, setToastMessage] = useState("");
  const [toastOpen, setToastOpen] = useState(false);
  const [toastSeverity, setToastSeverity] = useState("success");

  const years_select = generateYear_select();

  const schema = yup.object().shape({
    month: yup.string().required("El mes es requerido"),
    year: yup.string().required("El año es requerido")
  });

  const {
    control,
    handleSubmit,
    reset,
    formState: { isValid }
  } = useForm({
    resolver: yupResolver(schema),
    mode: "onChange",
    defaultValues: {
      month: getCurrentMonth(),
      year: getCurrentYear()
    }
  });

  const handleFormSubmit = (formData) => {
    const finalFormData = { ...formData, packageId: packageId.id };

    callApiAsync(
      () => createBillingPeriod(finalFormData),
      () => {
        setToastMessage(`Se agregó correctamente el periodo (${formData.month}/${formData.year}).`);
        setToastSeverity("success");
        setToastOpen(true);

        onSubmit(packageId.id);
      }
    );
    reset();
    onClose();
  };

  return (
    <div>
      <ModalComponent
        customWidth={"600px"}
        open={open}
        onClose={onClose}
        title={"Selecciona un periodo"}
      >
        <form onSubmit={handleSubmit(handleFormSubmit)}>
          <div className="mt-10">
            <SelectInput
              name="month"
              control={control}
              label="Mes"
              options={months_select}
              margin="10px 0 0 0 "
            />
          </div>
          <div className="mt-10">
            <SelectInput
              name="year"
              control={control}
              label="Año"
              options={years_select}
              margin="10px 0 0 0 "
            />
          </div>
          <div className="HContainer j-center mt-20">
            <ButtonComponent
              type="submit"
              backgroundColor="#F6C079"
              textColor="#000000"
              width="160px"
            >
              Guardar
            </ButtonComponent>
          </div>
        </form>
      </ModalComponent>
      <ToastNotification
        open={toastOpen}
        handleClose={() => setToastOpen(false)}
        message={toastMessage}
        severity={toastSeverity}
      />
    </div>
  );
};

export default BillingPeriod;
