import React, { useEffect } from "react";
import ModalComponent from "../../components/modalComponent";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import TextInput from "../../components/textInput";
import ButtonComponent from "../../components/button";

const CreateEditModal = ({
  rowData,
  tableData,
  open,
  onClose,
  onSubmitFuntion
}) => {
  const schema = yup.object().shape({
    name: yup
      .string()
      .required("El nombre del producto es requerido")
      .test(
        "uniqueName",
        "El nombre ya está en uso",
        (value) => !tableData.some((product) => product.productName === value)
      )
  });

  const {
    control,
    handleSubmit,
    reset
    // formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      name: rowData.productName || ""
    }
  });

  useEffect(() => {
    if (open) {
      reset({
        name: rowData.productName || ""
      });
    }
  }, [open, rowData, reset]);

  const handleFormSubmit = (formData) => {
    rowData.id
      ? onSubmitFuntion({ id: rowData.id, ...formData })
      : onSubmitFuntion({ id: 0, ...formData });
    onClose();
  };

  return (
    <ModalComponent
      open={open}
      onClose={onClose}
      onSubmit={handleSubmit(handleFormSubmit)}
      title={rowData.id ? "Editar Producto" : "Crear Producto"}
    >
      <form onSubmit={handleSubmit(handleFormSubmit)}>
        <TextInput name="name" control={control} label="Nombre del producto" />
        <div className="HContainer j-center mt-20">
          <ButtonComponent
            type="submit"
            backgroundColor="#F6C079"
            textColor="#000000"
            width="160px"
          >
            Guardar
          </ButtonComponent>
        </div>
      </form>
    </ModalComponent>
  );
};

export default CreateEditModal;
