import React from "react";
import { Controller } from "react-hook-form";
import {
  TextField,
  FormControl,
  FormHelperText,
  Autocomplete,
} from "@mui/material";

const SearchableSelectInput = ({
  name,
  control,
  label,
  options,
  defaultValue = "",
  rules = {},
}) => {
  return (
    <FormControl fullWidth variant="outlined">
      <Controller
        name={name}
        control={control}
        defaultValue={defaultValue}
        rules={rules}
        render={({ field, fieldState: { error } }) => (
          <>
            <Autocomplete
              options={options}
              getOptionLabel={(option) => option.label || ""}
              value={options.find((option) => option.value === field.value) || null}
              onChange={(_, data) => field.onChange(data ? data.value : "")}
              isOptionEqualToValue={(option, value) => option.value === value}
              noOptionsText="No se encontraron resultados"
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={label}
                  variant="outlined"
                  // error={!!error}
                  // helperText={error ? error.message : null}
                />
              )}
            />
            <FormHelperText>{error ? error.message : null}</FormHelperText>
          </>
        )}
      />
    </FormControl>
  );
};

export default SearchableSelectInput;
