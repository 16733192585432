import React, { useEffect } from "react";
import ModalComponent from "../../../components/modalComponent";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import TextInput from "../../../components/textInput";
import ButtonComponent from "../../../components/button";

const CreateCountryModal = ({
  countrysData,
  open,
  onClose,
  onSubmitFuntion
}) => {
  const schema = yup.object().shape({
    name: yup
      .string()
      .required("El nombre del País es requerido")
      .test(
        "uniqueName",
        "El nombre ya está en uso",
        (value) =>
          !countrysData.some((country) => country.label === value.toUpperCase())
      )
  });

  const {
    control,
    handleSubmit,
    reset,
    formState: { errors }
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      name: ""
    }
  });

  useEffect(() => {
    if (!open) {
      reset();
    }
  }, [open, reset]);

  const handleFormSubmit = (formData) => {
    onSubmitFuntion(formData);
    reset();
    onClose();
  };

  return (
    <ModalComponent
      open={open}
      onClose={() => {
        reset();
        onClose();
      }}
      onSubmit={handleSubmit(handleFormSubmit)}
      title={"Agregar país"}
    >
      <form onSubmit={handleSubmit(handleFormSubmit)}>
        <TextInput
          name="name"
          control={control}
          label="Nombre del País"
          error={!!errors.name}
          helperText={errors.name?.message}
        />
        <div className="HContainer j-center mt-20">
          <ButtonComponent
            type="submit"
            backgroundColor="#F6C079"
            textColor="#000000"
            width="160px"
          >
            Guardar
          </ButtonComponent>
        </div>
      </form>
    </ModalComponent>
  );
};

export default CreateCountryModal;
