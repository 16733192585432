import dayjs from "dayjs";

export const getCurrentYear = () => {
    return dayjs().year();
  };

  export const getCurrentMonth = () => {
    return dayjs().month() + 1;
  };

export const generateYear_select = () => {
  const currentYear = getCurrentYear();
  const yearsArray = [];

  for (let i = -1; i <= 2; i++) {
    const year = currentYear + i;
    yearsArray.push({
      label: year.toString(),
      value: year,
    });
  }

  return yearsArray;
};

export const getMonthName = (monthNumber) => {
  const months = [
    "Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio",
    "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"
  ];
  
  return months[monthNumber - 1] || "Mes inválido";
};
