import React, { useEffect, useMemo, useState } from "react";
import ModalComponent from "../../../components/modalComponent";
import TableComponent from "../../../components/tableComponent";
import ButtonComponent from "../../../components/button";
import icons from "../../../constants/icons";
import { callApiAsync, getSysUsers } from "../../../services/apiService";
import {
  hideColumn,
  textColumn
} from "../../../components/tableComponent/columns";
import StatusPill from "../../../components/statusPill";

const SelectUserModal = ({
  open,
  onClose,
  onSubmit,
  selectedUsers,
  enterpriseKey
}) => {
  const [userTable, setUserTable] = useState([]);
  const [localSelectedUsers, setLocalSelectedUsers] = useState([]);

  const getUserData = () => {
    callApiAsync(
      () => getSysUsers(enterpriseKey),
      (data) => {
        const filteredUsers = data.filter(
          (user) => !selectedUsers.some((selected) => selected.id === user.id)
        );
        setUserTable(filteredUsers);
      }
    );
  };

  const userTablecolumns = useMemo(
    () => [
      hideColumn("id", "ID de Usuario"),
      textColumn("email", "Email"),
      {
        id: "emailConfirmed",
        header: "Estado",
        Cell: ({ row }) => {
          const status = row.original.emailConfirmed;
          return (
            <StatusPill
              backgroundColor={status === true ? "#4ADE80" : "#D9D9D9"}
              textColor={"#000000"}
            >
              {status === true ? "Activo" : "Inactivo"}
            </StatusPill>
          );
        }
      }
    ],
    []
  );

  useEffect(() => {
    getUserData();
    setLocalSelectedUsers(selectedUsers);
  }, [selectedUsers, enterpriseKey]);

  const handleUserRowClick = (rowData) => {
    const isSelected = localSelectedUsers.some(
      (user) => user.id === rowData.id
    );
    if (isSelected) {
      setLocalSelectedUsers(
        localSelectedUsers.filter((user) => user.id !== rowData.id)
      );
    } else {
      setLocalSelectedUsers([...localSelectedUsers, rowData]);
    }
  };

  const handleSave = () => {
    onSubmit(localSelectedUsers);
    onClose();
  };

  return (
    <div>
      <ModalComponent
        customWidth={"600px"}
        open={open}
        onClose={onClose}
        title={"Selecciona Usuarios"}
      >
        <TableComponent
          columns={userTablecolumns}
          data={userTable}
          onRowClick={handleUserRowClick}
          selectedRows={localSelectedUsers}
          multiSelect={true}
          enableGlobalFilter={true}
        />
        <div className="VContainer">
          <p>Usuarios seleccionados: {localSelectedUsers.length}</p>
          <div className="HContainer j-center">
            <ButtonComponent
              onClick={handleSave}
              type="button"
              backgroundColor="#F6C079"
              textColor="#000000"
            >
              Guardar
            </ButtonComponent>
          </div>
        </div>
      </ModalComponent>
    </div>
  );
};

export default SelectUserModal;
