import React from "react";

const StatusPill = ({
  children,
  backgroundColor = "#4CAF50",
  textColor = "#ffffff",
  height = "25px",
  width = "85px",
  className = "",
}) => {
  return (
    <div
      className={`j-center pill ${className}`}
      style={{
        backgroundColor: backgroundColor,
        color: textColor,
        height: height,
        width: width,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        borderRadius: "5px", 
        textTransform: "none",
      }}
    >
      {children}
    </div>
  );
};

export default StatusPill;
