import React, { useEffect, useState, useMemo } from "react";
import {
  callApiAsync,
  getMainProducts,
  productCreateOrUpdate
} from "../../services/apiService";
import TableComponent from "../../components/tableComponent";
import {
  actionsColumn,
  textColumn
} from "../../components/tableComponent/columns";

import CreateEditModal from "./createEditProductModal";
import FeaturesModal from "./features/featuresModal";
import ButtonComponent from "../../components/button";
import icons from "../../constants/icons";
import ToastNotification from "../../components/toastNotification";

const Products = () => {
  const [productsData, setProductsData] = useState([]);

  const [openCreateEdit, setOpenCreateEdit] = useState(false);
  const [openFeatures, setOpenFeature] = useState(false);

  const [selectedRow, setSelectedRow] = useState({});

  const [toastMessage, setToastMessage] = useState("");
  const [toastOpen, setToastOpen] = useState(false);
  const [toastSeverity, setToastSeverity] = useState("success");

  const getProductTable = () => {
    callApiAsync(getMainProducts, (data) => {
      setProductsData(data);
    });
  };

  useEffect(() => {
    getProductTable();
  }, []);

  const createUpdateProduct = (productData) => {
    callApiAsync(
      () => productCreateOrUpdate(productData),
      (data) => {
        getProductTable();
        const message = productData.id
          ? "Producto editado correctamente"
          : "Producto creado correctamente";
        setToastMessage(message);
        setToastSeverity("success");
        setToastOpen(true);
      }
    );
  };

  const handleCreateEdit = (row) => {
    if (!row) {
      setSelectedRow({});
      setOpenCreateEdit(true);
      return;
    }
    setSelectedRow(row);
    setOpenCreateEdit(true);
  };

  const handleFeatures = (row) => {
    setSelectedRow(row);
    setOpenFeature(true);
  };

  const handleClose = () => {
    setOpenCreateEdit(false);
    setOpenFeature(false);
  };

  const actionBtn = ({ row }) => (
    <div className="HContainer">
      <ButtonComponent
        onClick={() => handleCreateEdit(row.original)}
        variant="contained"
        backgroundColor="#fff0"
      >
        <img className="btn-icon" src={icons.blue_edit} alt="icon" />
      </ButtonComponent>
      <ButtonComponent
        onClick={() => handleFeatures(row.original)}
        variant="contained"
        backgroundColor="#fff0"
      >
        <img className="btn-icon" src={icons.start} alt="icon" />
      </ButtonComponent>
    </div>
  );

  const columns = useMemo(
    () => [
      textColumn("productName", "Nombre del producto"),
      actionsColumn(actionBtn, 50)
    ],
    []
  );

  return (
    <div>
      <h1>Productos</h1>
      <div>
        <TableComponent
          columns={columns}
          data={productsData}
          enableGlobalFilter={true}
          headerButtons={
            <>
              <ButtonComponent
                onClick={() => handleCreateEdit()}
                type="submit"
                variant="contained"
                backgroundColor="#F6C079"
                textColor="#000000"
                margin="15px 0"
              >
                <div className="HContainer a-center">
                  <img
                    className="btn-icon icon-margin-r-15"
                    src={icons.plus}
                    alt="icon"
                  />
                  <p>Crear Productos</p>
                </div>
              </ButtonComponent>
            </>
          }
        />
      </div>

      <FeaturesModal
        tableData={productsData}
        rowDataProduct={selectedRow}
        open={openFeatures}
        onClose={handleClose}
      />
      <CreateEditModal
        tableData={productsData}
        rowData={selectedRow}
        open={openCreateEdit}
        onClose={handleClose}
        onSubmitFuntion={createUpdateProduct}
      />

      <ToastNotification
        open={toastOpen}
        handleClose={() => setToastOpen(false)}
        message={toastMessage}
        severity={toastSeverity}
      />
    </div>
  );
};

export default Products;
