import { styled, Tooltip, tooltipClasses } from "@mui/material";

export const TooltipComponent = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} arrow />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: "white",
      boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.2)",
      color: "black",
      fontWeight: 400,
      padding: "15px 20px",
      fontSize: '14px',
      borderRadius: "10px",
      fontFamily: "Lato",
      maxWidth: "300px",  
    },
  }));
  